<template>
  <tz-spinner
    v-if="isDashboard"
    class="um-dashboard-home__spinner"
    :is-check="false"
    spinner-size="140"
    :spinner-animation="true"
    spinner-color="#FF6363"
    spinner-stroke="2"
    :spinner-delay="1000"
    :is-starting-spin="true"
  />
  <section v-else class="um-error">
    <div class="um-error__inner">
      <header class="um-error__caption">
        <div class="um-error__status">{{ statusCode }}</div>
        <h1 class="um-error__heading">{{ message }}</h1>
        <p class="um-error__description">
          Please continue your search in the form below.
        </p>
      </header>
      <tz-search
        class="um-error__search"
        :people-link="peopleURL"
        :prefill-fields="prefillFields"
        :fallback-fields="fallbackFields"
        :no-query-string="true"
        @tz-search-override-redirect="navigateToSeo"
      />
    </div>
  </section>
</template>
<script>
import TzSearch from '@trazi/tz-search/src/tz-search.vue';
import TzSpinner from '@trazi/tz-spinner/src/tz-spinner.vue';
import formatCity from '@/assets/js/shared/helpers/formatCity';
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';
import userLocation from '@/mixins/shared/userLocation';

export default {
  mixins: [userLocation],
  components: {
    TzSearch,
    TzSpinner
  },
  data() {
    return {
      peopleURL: `https://unmask.com/searching/`,
      fallbackFields: [{ aid: 25 }, {}],
      prefillFields: {
        firstName: '',
        lastName: '',
        city: '',
        state: this.$store.getters['location/state']
      }
    };
  },
  methods: {
    /**
     * Takes user to phone/people SEO page
     * @param {object} event
     */
    navigateToSeo(event) {
      const {
        firstName,
        lastName,
        city,
        state,
        phoneNumber,
        street,
        searchType
      } = event;
      const { query } = this.$route;
      let searchLink = '';

      switch (searchType) {
        case 'address':
          const streetParam = street.replace(/ /g, '-');
          const cityParam = formatCity({ city }).replace(/ /g, '_');
          searchLink = this.$router.resolve({
            name: 'results.address',
            query: { ...query },
            params: {
              street: streetParam,
              city: cityParam,
              state
            }
          }).href;
          break;
        case 'phone':
          const rawPhoneNumber = phoneNumber.replace(/\D+/g, '');
          const phoneParam = dashedPhoneNumber({ phoneNumber: rawPhoneNumber });
          searchLink = this.$router.resolve({
            name: 'seo.phone',
            query: { ...query },
            params: {
              phone: phoneParam
            }
          }).href;
          break;
        case 'people':
          const searchQuery = {
            firstName: setTitleCase({ text: firstName }),
            lastName: setTitleCase({ text: lastName }),
            city: formatCity({ city }),
            state
          };
          searchLink = this.$router.resolve({
            name: 'flow.searching',
            params: { landingID: this.landingID },
            query: {
              ...query,
              time: Date.now(),
              ...searchQuery
            }
          }).href;
          break;
      }

      window.location = searchLink;
    }
  },
  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500;
    },
    message() {
      return this.error.message || 'Error';
    },
    isDashboard() {
      return this.$route.fullPath.includes('dashboard');
    }
  },
  mounted() {
    this.$bugsnag.notify(new Error('404 Error'));
  },
  layout(context) {
    if (context.route.fullPath.includes('dashboard')) {
      return 'dashboard';
    }
  },
  head() {
    return {
      title: '404 - This page could not be found'
    };
  },
  props: ['error']
};
</script>
