const middleware = {}

middleware['full-names-redirect'] = require('../middleware/full-names-redirect.js')
middleware['full-names-redirect'] = middleware['full-names-redirect'].default || middleware['full-names-redirect']

middleware['last-names-letter-redirect'] = require('../middleware/last-names-letter-redirect.js')
middleware['last-names-letter-redirect'] = middleware['last-names-letter-redirect'].default || middleware['last-names-letter-redirect']

middleware['last-names-state-redirect'] = require('../middleware/last-names-state-redirect.js')
middleware['last-names-state-redirect'] = middleware['last-names-state-redirect'].default || middleware['last-names-state-redirect']

middleware['shared/address-redirect'] = require('../middleware/shared/address-redirect.js')
middleware['shared/address-redirect'] = middleware['shared/address-redirect'].default || middleware['shared/address-redirect']

middleware['shared/generate-pdf'] = require('../middleware/shared/generate-pdf.js')
middleware['shared/generate-pdf'] = middleware['shared/generate-pdf'].default || middleware['shared/generate-pdf']

middleware['shared/results-redirect'] = require('../middleware/shared/results-redirect.js')
middleware['shared/results-redirect'] = middleware['shared/results-redirect'].default || middleware['shared/results-redirect']

middleware['shared/set-headers'] = require('../middleware/shared/set-headers.js')
middleware['shared/set-headers'] = middleware['shared/set-headers'].default || middleware['shared/set-headers']

middleware['shared/trending-name-redirect'] = require('../middleware/shared/trending-name-redirect.js')
middleware['shared/trending-name-redirect'] = middleware['shared/trending-name-redirect'].default || middleware['shared/trending-name-redirect']

middleware['street-bucket'] = require('../middleware/street-bucket.js')
middleware['street-bucket'] = middleware['street-bucket'].default || middleware['street-bucket']

export default middleware
