import getStateFromAbbr from '@/assets/js/shared/helpers/getStateFromAbbr';
import formatCity from '@/assets/js/shared/helpers/formatCity';
import formatNameCaseWithSplitterAndSpacer from '@/assets/js/shared/helpers/formatNameCaseWithSplitterAndSpacer';
import dashedPhoneNumber from '@/assets/js/shared/helpers/dashedPhoneNumber';
/**
 * Gets the SEO link for Root, State and City
 * @param {object} opts
 * @param {object} opts.params
 * @param {string} opts.seoType
 * @param {object} opts.router
 * @param {object} opts.query
 */
const getSeoLink = (opts = {}) => {
  if (!opts || !opts.params || !opts.seoType) {
    return '';
  }

  const { firstname, lastname, state, city, street, phone, uuid } = opts.params;
  const firstName = firstname
    ? formatNameCaseWithSplitterAndSpacer({ name: firstname })
    : '';
  const lastName = lastname
    ? formatNameCaseWithSplitterAndSpacer({ name: lastname, spacer: '-' })
    : '';

  const cityParam = city ? formatCity({ city }).replace(/\s/g, '-') : '';
  const streetParam = street ? street.replace(/\s/g, '-') : '';
  const phoneParam = phone ? dashedPhoneNumber({ phoneNumber: phone }) : '';
  const routeName = `seo.${opts.seoType}`;
  const hasLetterParam = false;
  const useStateAbbreviation = false;

  let letterParam;
  if (hasLetterParam && lastname) {
    letterParam = lastname.charAt(0);
  }

  let stateParam;
  if (state) {
    stateParam =
      useStateAbbreviation || opts.seoType === 'address'
        ? state
        : getStateFromAbbr({
            stateAbbr: state,
            includeTerritories: true
          }).replace(/\s/g, '-');
  }

  // Manually Setting URL for State & City Links For Sidebar Component
  if (!opts.router) {
    return `/${stateParam}/${cityParam}`.toLowerCase();
  }

  const { href } = opts.router.resolve({
    name: routeName,
    params: {
      firstName,
      lastName,
      state: stateParam,
      city: cityParam,
      street: streetParam,
      letter: letterParam,
      phone: phoneParam,
      uuid
    },
    query: opts.query
  });
  const hasTrailingSlash =
    !opts.query || !Object.keys(opts.query).length ? `/` : '';

  return routeName === 'seo.address'
    ? href
    : href.toLowerCase() + hasTrailingSlash;
};

export default getSeoLink;
