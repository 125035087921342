<template>
  <client-only v-if="showLoggedInUserElements">
    <div>
      <wl-header
        id="header"
        :logo-title="header.logo_title"
        :menu="dashboardMenu"
        :logo-path="`/dashboard/`"
        :path="path"
        :is-fixed="true"
        :hide-menu="isLanding"
        :show-aux-menu="isDashboard"
        class="wl-header--dashboard"
        :class="{
          'wl-header--mobile-search': mobileSearchActive && isMobile
        }"
      >
        <template #header-logo>
          <a
            class="wl-header__logo"
            href="/dashboard"
            :title="header.logo_title"
          >
            <span class="wl-header__logo-text">UnM</span
            ><span class="wl-header__logo-image">a</span
            ><span class="wl-header__logo-text">sk</span>
          </a>
        </template>
        <template #header-bar v-if="!isLanding">
          <button
            type="button"
            id="searchToggle"
            class="um-search-toggle"
            aria-controls="searchBar"
            aria-expanded="false"
            aria-label="Toggle Search"
            v-touch:tap="mobileSearchOpen"
          >
            {{ mobileSearchText }}
            <img
              :src="header.mobile_search_icon"
              alt="Search Icon"
              loading="lazy"
              width="15px"
              height="15px"
            />
          </button>
          <button
            type="button"
            id="searchClose"
            class="um-search-cancel"
            v-touch:tap="mobileSearchClose"
          >
            Cancel
          </button>
          <tz-search
            v-if="!isErrorPage && !isContactOrBlog"
            :in-header="true"
            :prefill-fields="prefillFields"
            :fallback-fields="fallbackFields"
            :people-link="peopleURL()"
            class="tz-search--dashboard"
            :has-permission="hasReportPermission"
            @handle-no-permission="togglePermissionModal"
          />
          <div class="um-dropdown-menu">
            <ul class="um-dropdown-menu__items">
              <li
                v-for="(navItem, index) in dashboardDropdownMenu"
                class="um-dropdown-menu__item"
                :key="`dropdownItem${index}`"
              >
                <button
                  v-if="navItem.label === 'Log Out'"
                  v-touch:tap="handleLogOut"
                  class="um-dropdown-menu__item-content--logout"
                >
                  {{ navItem.label }}
                </button>
                <button
                  v-else
                  v-touch:tap="
                    async () => navigateToDashboardRoute(navItem.url)
                  "
                  class="um-dropdown-menu__item-content"
                >
                  {{ navItem.label }}
                </button>
              </li>
            </ul>
          </div>
        </template>
        <um-account-modal
          :is-modal-open="isPermissionModalOpen"
          @um-account-modal-close="togglePermissionModal"
          class="um-account-modal--upsell"
        >
          <um-upsell
            :heading="accountCMS.subscription.renew_text"
            :sub-heading="accountCMS.subscription.no_subscription_text"
            :perks="accountCMS.subscription.perks"
            :submitText="accountCMS.subscription.no_subscription_submit_text"
            :cancelButtonText="accountCMS.buttons.cancel"
            @um-account-modal-close="togglePermissionModal"
          />
        </um-account-modal>
      </wl-header>
      <div class="inside-content" ref="insideContent">
        <div
          class="um-dashboard"
          :class="{
            'um-dashboard--not-dashboard-route': !showDashboardRouteElements
          }"
        >
          <ul v-if="showDashboardRouteElements" class="um-dashboard__menu">
            <li
              class="um-dashboard__menu-item"
              v-for="(link, index) in header.dashboard_navigation_menu"
              :key="`menuItem${index}`"
            >
              <router-link :to="link.url">{{ link.label }}</router-link>
            </li>
          </ul>
          <div class="um-dashboard__main">
            <nuxt ref="page" :prefill-fields="prefillFields" />
          </div>
          <div v-if="showDashboardRouteElements" class="um-dashboard__sidebar">
            <WlCard :title="sidebar.contact.title" class="wl-card--help">
              <TzMarkdown :content="sidebar.contact.subtext" />
            </WlCard>
          </div>
        </div>
        <tz-footer
          class="tz-footer--dashboard"
          :header-fixed="isFixed"
          :footer="{
            ...footer,
            disclaimer: dashboardDisclaimer
          }"
          :has-trailing-slash="true"
          :alpha-base-link="'/people/'"
          :display-sections="['disclaimer']"
          :page="{
            path: $route.path,
            name: $route.name,
            params: $route.params
          }"
          :show-scroll-to-top="true"
          v-on:scroll-to-top="scrollToTop"
        />
      </div>
    </div>
  </client-only>
</template>
<script>
// components
import WlHeader from '@/components/shared/wl-header.vue';
import TzSearch from '@trazi/tz-search/src/tz-search.vue';
import TzFooter from '@trazi/tz-footer/src/tz-footer.vue';
import TzSpinner from '@trazi/tz-spinner/src/tz-spinner.vue';
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
import UmAccountModal from '@/components/um-account-modal';
import UmUpsell from '@/components/um-upsell';
import WlCard from '@/components/shared/wl-card';
import helpers from '@/assets/js/shared/misc/helpers';
import { unregisterAndClearCaches } from '@/assets/js/shared/service-worker';

import userLocation from '@/mixins/shared/userLocation';
import mediaQueryListener from '@/assets/js/shared/helpers/mediaQueryListener';
import getMobileQuery from '../assets/js/shared/helpers/getMobileQuery';
const mobileMediaQuery = getMobileQuery({ width: 992 });
const footerCMS = require(`@/assets/cms/components/footer.json`);

export default {
  mixins: [userLocation],
  components: {
    TzSearch,
    TzFooter,
    TzSpinner,
    TzMarkdown,
    UmAccountModal,
    UmUpsell,
    WlCard,
    WlHeader
  },
  props: {
    footer: {
      type: Object,
      default: () => footerCMS
    },
    header: {
      type: Object,
      default: () => require(`@/assets/cms/components/header.json`)
    },
    sidebar: {
      type: Object,
      default: () => require(`@/assets/cms/components/sidebar.json`)
    },
    accountCMS: {
      type: Object,
      default: () =>
        require(`@/assets/cms/pages/dashboard/dashboard-account.json`)
    }
  },
  data() {
    return {
      logoSize: { height: '29', width: '159' },
      logoSmallSize: { height: '25', width: '26' },
      isMobile: false,
      isErrorPage: false,
      mobileSearchActive: false,
      mobileSearchText: '',
      isFixed: false,
      prefillFields: {
        firstName: '',
        lastName: '',
        city: '',
        state: this.$store.getters['location/state']
      },
      fallbackFields: [{ aid: 25 }, {}],
      params: {},
      path: null,
      showSpinner: this.checkRouteIsInDashboard(),
      showLoggedInUserElements: false,
      showDashboardRouteElements: this.checkRouteIsInDashboard(),
      dashboardDisclaimer: this.footer.dashboard_disclaimer,
      mobileNavOpened: false,
      userCurrent: null,
      dashboardMenu: [
        {
          label: '',
          url: '#'
        }
      ],
      siteURL: '',
      hasReportPermission: false,
      isPermissionModalOpen: false,
      hasPermission: false
    };
  },
  computed: {
    isDashboard() {
      // will not display the sidebar and widget on the person profile and search pages
      return (
        helpers.isDashboardRoute.test(this.$route.name) &&
        !helpers.isDashboardSubRoutes.test(this.$route.name)
      );
    },
    isContactOrBlog() {
      return (
        this.$route.path.includes('contact') ||
        this.$route.path.includes('blog')
      );
    },
    isLanding() {
      return (
        this.$route.path.includes('searching') ||
        this.$route.path.includes('results') ||
        this.$route.path.includes('landing')
      );
    },
    isCornerStone() {
      return /cornerstone/g.test(this.$route.name);
    },
    isEmptyQuery() {
      return Object.keys(this.$route.query).length === 0;
    },
    dashboardDropdownMenu() {
      const dropdownMenu = [...this.header.dashboard_navigation_menu];
      dropdownMenu.push({
        label: 'Log Out',
        url: '#'
      });
      return dropdownMenu;
    }
  },
  async created() {
    this.isErrorPage = this.$route.name === null;
    this.path = this.$route.path;

    this.updatePrefillFields(this.$route.query);
    this.setMobileText();

    if (this.showLoggedInUserElements) {
      // locking into having the search bar in view at all times.
      this.isFixed = true;
    }
  },
  async mounted() {
    const isUserLoggedIn = this.$authHelper.isUserLoggedInSync();

    if (isUserLoggedIn) {
      const userCurrent = await this.$authHelper.getCurrentUser();
      if (userCurrent === 'Access Denied') {
        await this.$authHelper.logoutUser('/login');
      }
      this.userCurrent = userCurrent;
      this.setDashboardMenuLabel(userCurrent);
      this.showLoggedInUserElements = true;
      this.showSpinner = false;
      await this.setUserPermissions();
    }

    if (isUserLoggedIn && this.shouldRedirectIfLoggedIn()) {
      await this.$router.push({ name: 'dashboard-reports' });
      this.showDashboardRouteElements = true;
      this.isFixed = true;
    }

    if (!isUserLoggedIn && this.isDashboard) {
      this.redirect(this.$router, 'login');
    }

    //Check SessionStorage To See If Upsell Modal Needs To Display
    this.checkShowPermissionModal();

    this.siteURL = window.location.origin;

    if (this.$route.name === 'dashboard-reports') {
      this.currentUser.state = this.$store.getters['location/state'] ?? '';
      this.currentUser.city = this.$store.getters['location/city'] ?? '';
      this.currentUser.country = this.$store.getters['location/country'] ?? '';
    }

    // Remove any registered service workers
    unregisterAndClearCaches();

    window.onpageshow = function(event) {
      /* Safari Back Button Persistent Cache Fix */
      if (event.persisted) {
        window.location.reload();
      }
    };

    mediaQueryListener({
      selector: mobileMediaQuery,
      handleMethod: this.setIsMobile
    });
    this.isMobile = mobileMediaQuery.matches;
  },
  beforeDestroy() {
    mobileMediaQuery.removeEventListener('change', this.setIsMobile);
  },
  methods: {
    setIsMobile(e) {
      this.isMobile = e.matches;
    },
    /**
     * Redirects user
     * @param {object} router
     * @param {string} routeName
     */
    redirect(router, routeName) {
      const { href } = router.resolve({
        name: routeName
      });
      location.href = href;
    },
    /**
     * returns path for people search
     * @returns {String}
     */
    peopleURL() {
      return this.showLoggedInUserElements
        ? `${this.siteURL}/dashboard/search`
        : `https://unmask.com/searching/`;
    },
    /**
     * Handle logout
     * @returns {Promise<void>}
     */
    handleLogOut() {
      this.$authHelper.logoutUser('/login');
    },
    mobileSearchOpen() {
      this.mobileSearchActive = true;
      document.querySelector('.wl-header--open-nav #btn-menu')?.click();
    },
    mobileSearchClose() {
      this.mobileSearchActive = false;
    },
    setMobileText() {
      this.mobileSearchText = this.header.mobile_search_label;

      if (this.$route.query.firstName || this.$route.query.lastName) {
        this.mobileSearchText = `Find ${this.$route.query.firstName ||
          ''} ${this.$route.query.lastName || ''}`;
      }
    },
    scrollToTop() {
      scroll({
        top: 0,
        behavior: 'smooth'
      });
    },
    updatePrefillFields(updatedFields) {
      this.prefillFields = Object.assign({}, this.prefillFields, updatedFields);
    },
    shouldRedirectIfLoggedIn() {
      if (
        this.$route.name === 'dashboard-search' &&
        !this.hasReportPermission
      ) {
        sessionStorage.setItem('showPermissionModal', 'true');
        return true;
      }
      return !/dashboard|privacy|terms|contact|opt/.test(this.$route.name);
    },
    checkRouteIsInDashboard() {
      // Hides sidebar and widget on reports and searching page
      return (
        helpers.isDashboardRoute.test(this.$route.name) &&
        !helpers.isDashboardSubRoutes.test(this.$route.name)
      );
    },
    setDashboardMenuLabel(currentUser) {
      const rawLabel = currentUser.firstname
        ? currentUser.firstname
        : currentUser.email;
      const displayLabel =
        rawLabel.length > 30 ? `${rawLabel.slice(0, 30)}...` : rawLabel;
      this.dashboardMenu[0].label = displayLabel;
    },
    async navigateToDashboardRoute(routePath) {
      await this.$router.push({ path: routePath });
    },
    async setUserPermissions() {
      try {
        this.hasReportPermission = await this.$authHelper.checkUserPermission({
          feature: 'full-reports',
          userId: this.userCurrent.id
        });
      } catch (err) {
        console.error(err);
        this.$bugsnag.notify(err);
      }
    },
    checkShowPermissionModal() {
      if (sessionStorage.getItem('showPermissionModal') === 'true') {
        sessionStorage.removeItem('showPermissionModal');
        this.isPermissionModalOpen = true;
      }
    },
    togglePermissionModal() {
      this.isPermissionModalOpen = !this.isPermissionModalOpen;
    }
  },
  watch: {
    $route(to, from) {
      this.showDashboardRouteElements = this.checkRouteIsInDashboard();
      const isDropdownMenuOpen = document.querySelector('.wl-header--open-nav');
      if (isDropdownMenuOpen) {
        const menuCloseBtn = document.querySelector('.wl-header__btn-menu');
        menuCloseBtn.click();
      }
    }
  },
  updated() {
    this.showDashboardRouteElements = this.checkRouteIsInDashboard();
    this.checkShowPermissionModal();
  },
  head() {
    const head = {
      meta: [],
      script: []
    };
    const hasNoCrawling = /open-report|people-flow|results.profile|terms-conditions|privacy-policy/.test(
      this.$route.name
    );
    if (!head.meta) {
      head.meta = [];
    }

    // If route has no crawling add noindex nofollow noarchive and return early
    if (hasNoCrawling) {
      head.meta.push({
        name: 'robots',
        hid: 'robots',
        content: 'noindex, nofollow, noarchive'
      });

      return head;
    }

    const website = `https://${this.$config.website}.com`;

    // Setup nav links for nav schema
    const navLinks = this.header.menu
      .concat(
        [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'].map(letter => ({
          url: `/people/${letter}000000/`,
          name: letter
        }))
      )
      .concat(this.footer.secondary_list)
      .concat(this.footer.global_list);
    const navSchema = [];
    // Loop through links to build nav schema
    for (let i = 0; i < navLinks.length; i++) {
      const link = navLinks[i].url.startsWith('/')
        ? website + navLinks[i].url
        : navLinks[i].url;
      navSchema.push({
        '@context': 'https://schema.org/',
        '@type': 'SiteNavigationElement',
        '@id': link,
        url: link,
        name: navLinks[i].label
      });
    }
    // Push nav schema to head
    head.script.push({
      type: 'application/ld+json',
      json: { '@graph': navSchema }
    });

    // Push org schema to head
    head.script.push({
      type: 'application/ld+json',
      json: {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'UnMask',
        url: website,
        alternateName: 'UnMask.com',
        logo: `${website}/images/logo-solid.png`,
        contactPoint: [
          {
            '@type': 'ContactPoint',
            email: 'contact@unmask.com',
            url: `${website}/contact/`,
            contactType: 'Customer Service',
            areaServed: 'US'
          }
        ]
      }
    });

    return head;
  }
};
</script>
