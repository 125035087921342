import getAlphabetList from '@/assets/js/shared/helpers/getAlphabetList';

/**
 * @param route
 * @param redirect
 * @returns {*}
 */
export default function({ route, redirect }) {
  let redirectParams = {};
  let redirectUrl = '/most-popular-last-names/';
  const { params, fullPath } = route;
  const letter = params.letter.toUpperCase();
  const alphabet = getAlphabetList();
  const hasValidLetter = letter && !!alphabet.includes(letter);

  if (hasValidLetter) {
    redirectParams.letter = letter;
    redirectUrl = redirectUrl + 'letter/' + redirectParams.letter;

    if (route.name === 'top-last-names.letter') {
      redirectUrl = redirectUrl + '/';
    }
  } else {
    return redirect(301, redirectUrl + 'letter/');
  }

  if (fullPath !== redirectUrl) {
    return redirect(301, redirectUrl);
  }
}
