const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
];

/**
 * Returns an array of the alphabet
 *
 * @returns {string[]}
 */
const getAlphabetList = () => {
  return alphabet;
};

export default getAlphabetList;
