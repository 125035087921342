import getStateFromAbbr from '@/assets/js/shared/helpers/getStateFromAbbr';

/**
 * @param route
 * @param redirect
 * @returns {*}
 */
export default function({ route, redirect }) {
  let redirectParams = {};
  let redirectUrl = '/most-popular-last-names/';
  const { params, fullPath } = route;
  const state = params.state.toUpperCase();
  const hasValidState =
    state && !!getStateFromAbbr({ stateAbbr: state, includeTerritories: true });

  if (hasValidState) {
    redirectParams.state = state;
    redirectUrl = redirectUrl + 'state/' + redirectParams.state;

    if (route.name === 'top-last-names.state') {
      redirectUrl = redirectUrl + '/';
    }
  } else {
    return redirect(301, redirectUrl + 'state/');
  }

  if (fullPath !== redirectUrl) {
    return redirect(301, redirectUrl);
  }
}
