import getStateFromAbbr from '@/assets/js/shared/helpers/getStateFromAbbr';

export default function({ route, redirect }) {
  const { params, fullPath } = route;
  let redirectParams = {};
  let redirectUrl = '/address/states/';
  const stateParam = params.state ? params.state.toUpperCase() : '';
  const hasValidState =
    stateParam && !!getStateFromAbbr({ stateAbbr: stateParam });
  const cityParam = params.city || '';
  const zipCodePattern = /^\d{5}(?:-\d{4})?$/;
  const zipParam = params.zip && zipCodePattern.test(params.zip);
  const streetParam = params.street;
  const hasValidPage = params.page;

  if (hasValidState) {
    redirectParams.state = stateParam;
    redirectUrl = redirectUrl + redirectParams.state + '/';
  } else {
    return redirect(301, redirectUrl);
  }

  if (cityParam) {
    const underscoredCity = cityParam.replace(/-/g, '_');
    const capitalizedCity = underscoredCity
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('_');
    redirectParams.city = capitalizedCity;
    redirectUrl = redirectUrl + redirectParams.city + '/';
  }

  if (zipParam) {
    redirectParams.zip = params.zip;
    redirectUrl = redirectUrl + redirectParams.zip + '/';
  }

  if (streetParam) {
    const formattedStreet = streetParam.replace(/_| /g, '-');
    const capitalizedStreet = formattedStreet
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('-');
    redirectParams.street = capitalizedStreet;
    redirectUrl = redirectUrl + redirectParams.street + '/';
  }

  if (hasValidPage) {
    redirectParams.page = params.page;
    redirectUrl = redirectUrl + redirectParams.page + '/';
  }

  if (fullPath !== redirectUrl) {
    return redirect(301, redirectUrl);
  }
}
