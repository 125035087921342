var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.seoPagesClass},[(!_vm.isTrackingPage)?_c('wl-header',{class:{
      'wl-header--mobile-search': _vm.mobileSearchActive && _vm.isMobile,
      'wl-header--home': _vm.isHome,
      'wl-header--cornerstone': _vm.isCornerStone,
      'wl-header--landing': _vm.isLanding
    },attrs:{"id":"header","logo-title":_vm.header.logo_title,"menu":_vm.showFullToggleMenu() ? [] : _vm.header.menu.slice(0, _vm.menuLinksDesktopCount),"path":_vm.path,"is-fixed":_vm.isFixed,"hide-menu":_vm.isLanding,"show-aux-menu":!_vm.showFullToggleMenu() && !_vm.isLanding},scopedSlots:_vm._u([{key:"header-logo",fn:function(){return [_c('a',{staticClass:"wl-header__logo",attrs:{"href":"/","title":_vm.header.logo_title}},[_c('span',{staticClass:"wl-header__logo-text"},[_vm._v("UnM")]),_c('span',{staticClass:"wl-header__logo-image"},[_vm._v("a")]),_c('span',{staticClass:"wl-header__logo-text"},[_vm._v("sk")]),_c('span',{staticClass:"wl-header__logo-reg-trademark"},[_vm._v("®")])])]},proxy:true},(!_vm.isLanding)?{key:"header-bar",fn:function(){return [_c('button',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.mobileSearchOpen),expression:"mobileSearchOpen",arg:"tap"}],staticClass:"um-search-toggle",attrs:{"type":"button","id":"searchToggle","aria-controls":"searchBar","aria-expanded":"false","aria-label":"Toggle Search"}},[_c('span',{staticClass:"um-search-toggle__text"},[_vm._v(_vm._s(_vm.mobileSearchText))]),_vm._v(" "),_c('img',{attrs:{"src":_vm.header.mobile_search_icon,"alt":"Search Icon","loading":"lazy","width":"15px","height":"15px"}})]),_vm._v(" "),_c('button',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.mobileSearchClose),expression:"mobileSearchClose",arg:"tap"}],staticClass:"um-search-cancel",attrs:{"type":"button","id":"searchClose"}},[_vm._v("\n        Cancel\n      ")]),_vm._v(" "),(_vm.showDesktopSearchBar())?_c('tz-search',{attrs:{"in-header":true,"prefill-fields":_vm.prefillFields,"fallback-fields":_vm.fallbackFields,"people-link":_vm.peopleURL(),"no-query-string":true},on:{"tz-search-override-redirect":_vm.navigateToSeo}}):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"wl-header__toggle-menu",attrs:{"id":"toggle-menu"}},_vm._l((_vm.showFullToggleMenu()
            ? _vm.header.menu
            : _vm.header.menu.slice(_vm.menuLinksDesktopCount)),function(link){return _c('li',{key:link.label,staticClass:"wl-header__toggle-menu-item"},[_c('a',{staticClass:"wl-header__toggle-menu-link",class:{
              'wl-header__toggle-menu-link--active': _vm.path === link.url
            },attrs:{"href":link.url,"role":"button","aria-haspopup":"true","aria-expanded":"false"},domProps:{"textContent":_vm._s(link.label)}})])}),0)]},proxy:true}:null],null,true)}):_vm._e(),_vm._v(" "),_c('div',{ref:"insideContent",staticClass:"inside-content"},[_c('nuxt',{ref:"page",attrs:{"prefill-fields":_vm.prefillFields,"fallback-fields":_vm.fallbackFields}}),_vm._v(" "),_c('div',{staticClass:"footer-states"},[_c('div',{staticClass:"footer-states__container"},[_c('h2',{staticClass:"footer-states__title"},[_vm._v("Browse by States:")]),_vm._v(" "),_c('ul',{staticClass:"footer-states__list"},_vm._l((_vm.getStates()),function(stateName,stateAbbr){return _c('li',{key:stateAbbr,staticClass:"footer-states__item"},[_c('a',{staticClass:"footer-states__link",attrs:{"href":`/address/states/${stateAbbr}/`},domProps:{"textContent":_vm._s(stateAbbr)}})])}),0)])]),_vm._v(" "),(!_vm.isTrackingPage)?_c('tz-footer',{class:{
        'tz-footer--landing': _vm.isLanding,
        'tz-footer--default': !_vm.isLanding
      },attrs:{"header-fixed":_vm.isFixed,"footer":_vm.isLanding ? { ..._vm.footer, disclaimer: _vm.landingDisclaimer } : _vm.footer,"alpha-base-link":'/people/',"alpha-link-pad":true,"has-trailing-slash":true,"display-sections":_vm.isLanding
          ? ['globalLinks', 'disclaimer']
          : [
              'alphaSelect',
              'contactInfo',
              'columnPrimary',
              'columnSecondary',
              'columnTertiary',
              'columnQuarternary',
              'disclaimer'
            ],"page":{
        path: _vm.$route.path,
        name: _vm.$route.name,
        params: _vm.$route.params
      },"show-scroll-to-top":true},on:{"scroll-to-top":_vm.scrollToTop}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }