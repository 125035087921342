import getStateFromAbbr from '@/assets/js/shared/helpers/getStateFromAbbr';

/**
 * @param route
 * @param redirect
 * @returns {*}
 */
export default function({ route, redirect }) {
  const { params, fullPath } = route;
  const state = params.state.toUpperCase();
  const hasValidState =
    state && !!getStateFromAbbr({ stateAbbr: state, includeTerritories: true });
  let redirectParams = {};
  let redirectUrl = '/most-popular-full-names/';

  if (hasValidState) {
    redirectParams.state = state;
    redirectUrl = redirectUrl + redirectParams.state;
    if (route.name === 'top-full-names.state') {
      redirectUrl = redirectUrl + '/';
    }
  }

  if (fullPath !== redirectUrl) {
    return redirect(301, redirectUrl);
  }
}
