import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _774f894a = () => interopDefault(import('../pages/shared/privacy-policy/index.vue' /* webpackChunkName: "" */))
const _21f34b9e = () => interopDefault(import('../pages/shared/terms-conditions/index.vue' /* webpackChunkName: "" */))
const _de783be8 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "" */))
const _2bd1be42 = () => interopDefault(import('../pages/content/index.vue' /* webpackChunkName: "" */))
const _78c22f41 = () => interopDefault(import('../pages/cornerstone/criminal-records.vue' /* webpackChunkName: "" */))
const _333f4c87 = () => interopDefault(import('../pages/cornerstone/public-records.vue' /* webpackChunkName: "" */))
const _98ed418a = () => interopDefault(import('../pages/checkpeople-review/index.vue' /* webpackChunkName: "" */))
const _1d238929 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "" */))
const _d7c0285e = () => interopDefault(import('../pages/cornerstone/background-checks.vue' /* webpackChunkName: "" */))
const _06807b22 = () => interopDefault(import('../pages/cornerstone/phone-lookup.vue' /* webpackChunkName: "" */))
const _2c023586 = () => interopDefault(import('../pages/cornerstone/faq.vue' /* webpackChunkName: "" */))
const _51c0c6c8 = () => interopDefault(import('../pages/cornerstone/address-lookup.vue' /* webpackChunkName: "" */))
const _11ead9a4 = () => interopDefault(import('../pages/cornerstone/dos-donts.vue' /* webpackChunkName: "" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "" */))
const _6ae02208 = () => interopDefault(import('../pages/shared/forgot-password/index.vue' /* webpackChunkName: "" */))
const _15e4431b = () => interopDefault(import('../pages/shared/new-password/index.vue' /* webpackChunkName: "" */))
const _65ca94ca = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "" */))
const _b1dc3390 = () => interopDefault(import('../pages/dashboard/account.vue' /* webpackChunkName: "" */))
const _7f6a65cd = () => interopDefault(import('../pages/dashboard/billing-history.vue' /* webpackChunkName: "" */))
const _aaeafbe6 = () => interopDefault(import('../pages/dashboard/monitoring.vue' /* webpackChunkName: "" */))
const _54798b81 = () => interopDefault(import('../pages/dashboard/results.vue' /* webpackChunkName: "" */))
const _75e14eab = () => interopDefault(import('../pages/dashboard/contact.vue' /* webpackChunkName: "" */))
const _0086687a = () => interopDefault(import('../pages/dashboard/terms-conditions.vue' /* webpackChunkName: "" */))
const _2adde2dc = () => interopDefault(import('../pages/dashboard/privacy-policy.vue' /* webpackChunkName: "" */))
const _056f9c00 = () => interopDefault(import('../pages/dashboard/optout/request.vue' /* webpackChunkName: "" */))
const _3ff07f94 = () => interopDefault(import('../pages/dashboard/optout/confirmation.vue' /* webpackChunkName: "" */))
const _b005bd4c = () => interopDefault(import('../pages/dashboard/optout/optout.vue' /* webpackChunkName: "" */))
const _cd5ecd98 = () => interopDefault(import('../pages/dashboard/search-history.vue' /* webpackChunkName: "" */))
const _974d14e2 = () => interopDefault(import('../pages/reports/person.vue' /* webpackChunkName: "" */))
const _6a38beeb = () => interopDefault(import('../pages/dashboard/reactivate.vue' /* webpackChunkName: "" */))
const _ef69eba0 = () => interopDefault(import('../pages/index/letter/index.vue' /* webpackChunkName: "" */))
const _7684cdb6 = () => interopDefault(import('../pages/optout/request.vue' /* webpackChunkName: "" */))
const _483270e2 = () => interopDefault(import('../pages/optout/confirmation.vue' /* webpackChunkName: "" */))
const _6c5bc195 = () => interopDefault(import('../pages/optout/optout.vue' /* webpackChunkName: "" */))
const _14140b7a = () => interopDefault(import('../pages/searching/index.vue' /* webpackChunkName: "" */))
const _439f038d = () => interopDefault(import('../pages/results/no-results.vue' /* webpackChunkName: "" */))
const _4fd76502 = () => interopDefault(import('../pages/results/index.vue' /* webpackChunkName: "" */))
const _4e54d84e = () => interopDefault(import('../pages/step/step-one.vue' /* webpackChunkName: "" */))
const _66bc8934 = () => interopDefault(import('../pages/step/step-two.vue' /* webpackChunkName: "" */))
const _e1e9c0f4 = () => interopDefault(import('../pages/step/step-three.vue' /* webpackChunkName: "" */))
const _7f0e1115 = () => interopDefault(import('../pages/tracking/tracking.vue' /* webpackChunkName: "" */))
const _168b6cb3 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "" */))
const _632f8e5f = () => interopDefault(import('../pages/popular-name-calendar/day.vue' /* webpackChunkName: "" */))
const _ef826c56 = () => interopDefault(import('../pages/popular-name-calendar/index.vue' /* webpackChunkName: "" */))
const _97234e22 = () => interopDefault(import('../pages/top-full-names/index.vue' /* webpackChunkName: "" */))
const _683bfd2e = () => interopDefault(import('../pages/top-full-names/state.vue' /* webpackChunkName: "" */))
const _13944a14 = () => interopDefault(import('../pages/top-last-names/index.vue' /* webpackChunkName: "" */))
const _4b05d902 = () => interopDefault(import('../pages/top-last-names/alphabet.vue' /* webpackChunkName: "" */))
const _1c88a69c = () => interopDefault(import('../pages/top-last-names/letter.vue' /* webpackChunkName: "" */))
const _cbcfeee4 = () => interopDefault(import('../pages/top-last-names/states.vue' /* webpackChunkName: "" */))
const _abf90196 = () => interopDefault(import('../pages/top-last-names/state.vue' /* webpackChunkName: "" */))
const _cc19414c = () => interopDefault(import('../pages/search/phone-results.vue' /* webpackChunkName: "" */))
const _7dbe6620 = () => interopDefault(import('../pages/search/address-results.vue' /* webpackChunkName: "" */))
const _3c362179 = () => interopDefault(import('../pages/search/results.vue' /* webpackChunkName: "" */))
const _47b3c321 = () => interopDefault(import('../pages/search/city-results.vue' /* webpackChunkName: "" */))
const _7ffa49fd = () => interopDefault(import('../pages/search/state-results.vue' /* webpackChunkName: "" */))
const _7e63613f = () => interopDefault(import('../pages/results/results-profile.vue' /* webpackChunkName: "" */))
const _b419a706 = () => interopDefault(import('../pages/street-bucket/index.vue' /* webpackChunkName: "" */))
const _a2ecfd30 = () => interopDefault(import('../pages/street-bucket/street.vue' /* webpackChunkName: "" */))
const _3b0a3df4 = () => interopDefault(import('../pages/street-bucket/people.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/privacy-policy/",
    component: _774f894a,
    name: "privacy-policy"
  }, {
    path: "/terms-conditions/",
    component: _21f34b9e,
    name: "terms-conditions"
  }, {
    path: "/",
    component: _de783be8,
    name: "home"
  }, {
    path: "/app/",
    component: _2bd1be42,
    name: "app"
  }, {
    path: "/Criminal-Records/",
    component: _78c22f41,
    name: "cornerstone.criminal-records"
  }, {
    path: "/Public-Records/",
    component: _333f4c87,
    name: "cornerstone.public-records"
  }, {
    path: "/checkpeople-review-article/",
    component: _98ed418a,
    name: "checkpeople-review"
  }, {
    path: "/contact/",
    component: _1d238929,
    name: "contact"
  }, {
    path: "/Background-Checks/",
    component: _d7c0285e,
    name: "cornerstone.background-checks"
  }, {
    path: "/phone/",
    component: _06807b22,
    name: "cornerstone.phone-lookup"
  }, {
    path: "/frequently-asked-questions/",
    component: _2c023586,
    name: "cornerstone.faq"
  }, {
    path: "/address/",
    component: _51c0c6c8,
    name: "cornerstone.address-lookup"
  }, {
    path: "/dos-and-donts/",
    component: _11ead9a4,
    name: "cornerstone.dos-and-donts"
  }, {
    path: "/login",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/forgot-password/",
    component: _6ae02208,
    name: "forgot-password"
  }, {
    path: "/new-password/",
    component: _15e4431b,
    name: "new-password"
  }, {
    path: "/dashboard/:page(\\d+)?",
    component: _65ca94ca,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/account",
    component: _b1dc3390,
    name: "dashboard-account"
  }, {
    path: "/dashboard/account/billing-history/:page(\\d+)?",
    component: _7f6a65cd,
    name: "dashboard-billing-history"
  }, {
    path: "/dashboard/monitoring",
    component: _aaeafbe6,
    name: "dashboard-monitoring"
  }, {
    path: "/dashboard/search",
    component: _54798b81,
    name: "dashboard-search"
  }, {
    path: "/dashboard/contact",
    component: _75e14eab,
    name: "dashboard-contact"
  }, {
    path: "/dashboard/terms-conditions",
    component: _0086687a,
    name: "dashboard-terms-conditions"
  }, {
    path: "/dashboard/privacy-policy",
    component: _2adde2dc,
    name: "dashboard-privacy-policy"
  }, {
    path: "/dashboard/opt-out/request/",
    component: _056f9c00,
    name: "dashboard-optout-request"
  }, {
    path: "/dashboard/opt-out/confirmation/:token",
    component: _3ff07f94,
    name: "dashboard-optout-confirmation"
  }, {
    path: "/dashboard/opt-out/",
    component: _b005bd4c,
    name: "dashboard-optout"
  }, {
    path: "/dashboard/search/history",
    component: _cd5ecd98,
    name: "dashboard-search-history"
  }, {
    path: "/dashboard/report/person/:uuid",
    component: _974d14e2,
    name: "dashboard-reports-person-generate"
  }, {
    path: "/dashboard/report/:reportType/:reportCode",
    component: _974d14e2,
    name: "dashboard-reports-person"
  }, {
    path: "/dashboard/reactivate",
    component: _6a38beeb,
    name: "dashboard-reactivate"
  }, {
    path: "/people/:searchCode([A-Za-z]\\d{6})",
    component: _ef69eba0,
    name: "index.letter"
  }, {
    path: "/opt-out/request/",
    component: _7684cdb6,
    name: "optout-request"
  }, {
    path: "/opt-out/confirmation/:token",
    component: _483270e2,
    name: "optout-confirmation"
  }, {
    path: "/opt-out/",
    component: _6c5bc195,
    name: "optout"
  }, {
    path: "/searching/",
    component: _14140b7a,
    name: "flow.searching"
  }, {
    path: "/results/",
    component: _439f038d,
    name: "flow.results"
  }, {
    path: "/res/",
    component: _4fd76502,
    name: "flow.res"
  }, {
    path: "/pf/:landingID/1/",
    component: _4e54d84e,
    name: "flow.step1"
  }, {
    path: "/pf/:landingID/2/",
    component: _66bc8934,
    name: "flow.step2"
  }, {
    path: "/pf/:landingID/3/",
    component: _e1e9c0f4,
    name: "flow.step3"
  }, {
    path: "/landing/tracking/",
    component: _7f0e1115,
    name: "tracking"
  }, {
    path: "/pf/:landingID/checkout/",
    component: _168b6cb3,
    name: "flow.checkout"
  }, {
    path: "/trending-name-search/:year-:month-:day",
    component: _632f8e5f,
    name: "calendar-day-page"
  }, {
    path: "/trending-name-search/:year?",
    component: _ef826c56,
    name: "popular-name-calendar"
  }, {
    path: "/most-popular-full-names/",
    component: _97234e22,
    name: "top-full-names.root"
  }, {
    path: "/most-popular-full-names/:state",
    component: _683bfd2e,
    name: "top-full-names.state"
  }, {
    path: "/most-popular-last-names/",
    component: _13944a14,
    name: "top-last-names.root"
  }, {
    path: "/most-popular-last-names/letter/",
    component: _4b05d902,
    name: "top-last-names.alphabet"
  }, {
    path: "/most-popular-last-names/letter/:letter",
    component: _1c88a69c,
    name: "top-last-names.letter"
  }, {
    path: "/most-popular-last-names/state/",
    component: _cbcfeee4,
    name: "top-last-names.states"
  }, {
    path: "/most-popular-last-names/state/:state",
    component: _abf90196,
    name: "top-last-names.state"
  }, {
    path: "/phone/:phone/:page(\\d+)?",
    component: _cc19414c,
    name: "seo.phone"
  }, {
    path: "/address/:street--:city-:state-:zipcode/:page(\\d+)?",
    component: _7dbe6620,
    name: "results.address"
  }, {
    path: "/:firstName-:lastName/:page(\\d+)?",
    component: _3c362179,
    name: "results.root"
  }, {
    path: "/:firstName-:lastName/:stateAbbr-:city/:page(\\d+)?",
    component: _47b3c321,
    name: "results.city"
  }, {
    path: "/:firstName-:lastName/:stateAbbr/:page(\\d+)?",
    component: _7ffa49fd,
    name: "results.state"
  }, {
    path: "/:firstName-:lastName/:stateAbbr-:city/:uuid",
    component: _7e63613f,
    name: "results.profile"
  }, {
    path: "/address/states",
    component: _b419a706,
    name: "address.root"
  }, {
    path: "/address/states/:state",
    component: _b419a706,
    name: "address.cities"
  }, {
    path: "/address/states/:state/:city",
    component: _a2ecfd30,
    name: "address.zipcodes"
  }, {
    path: "/address/states/:state/:city/:zip/:page(\\d+)?",
    component: _a2ecfd30,
    name: "address.streets"
  }, {
    path: "/address/states/:state/:city/:zip/:street/:page(\\d+)?",
    component: _a2ecfd30,
    name: "address.streetnames"
  }, {
    path: "/address/people/:street",
    component: _3b0a3df4,
    name: "address.people"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
